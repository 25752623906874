import React from 'react'
import { ContactItem } from '..'

export const Contact = ({ contacts }) => {
  return (
    <section name='contact' id='contact' className='col gap-4'>
      <h1>Contact</h1>
      <div className='col gap-2'>
        {contacts.map((c, i) => (
          <ContactItem contact={c} key={i} />
        ))}
      </div>
    </section>
  )
}
