export default [
  {
    title: 'Bachelor of Computer Science',
    schoolName: 'Odessa State Environmental University 🇺🇦',
    schoolWebsite: 'http://odeku.edu.ua/',
    dates: '09.2012 - 08.2014',
  },
  {
    title: 'IT Specialist',
    schoolName: 'Technical School of Industrial Automatics 🇺🇦',
    schoolWebsite: 'http://promavt.od.ua/',
    dates: '09.2008 - 08.2012',
  },
]
