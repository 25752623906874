import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allImageSharp {
          edges {
            node {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.fluid.src.includes(props.filename)
      })
      if (!image) return null
      return <Img fluid={image.node.fluid} {...props} />
    }}
  />
)
