import React, { useEffect, useRef } from 'react'

export const LiveChatBot = () => {
  const containerRef = useRef()
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('id', 'TelegramLiveChatLoader')
    script.setAttribute('data-bot', 'A5ED4AD6-F2B3-11E9-8381-E2E922E822B5')
    script.setAttribute('src', '//livechatbot.net/assets/chat/js/loader.js')
    containerRef.current.appendChild(script)
    return () => {
      containerRef.current.removeChild(script)
    }
  }, [containerRef])
  return <span ref={containerRef} />
}
