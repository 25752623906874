import React from 'react'
import { withPrefix } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export const Link = ({ to, children, ...other }) => {
  const isExternal = path => {
    return (
      /^http[s]?:\/\//g.test(path) ||
      path.startsWith('mailto:') ||
      path.startsWith('tel:')
    )
  }

  const getRedirectUrl = path => {
    return isExternal(path) ? path : withPrefix(path)
  }

  return (
    <OutboundLink href={getRedirectUrl(to)} {...other}>
      {children}
    </OutboundLink>
  )
}
