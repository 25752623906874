import React, { useState } from 'react'
import map from 'lodash/map'
import styled from 'styled-components'

const SectionShowMore = styled.section`
  overflow: hidden;
  position: relative;
  &:after {
    content: '↓ More...';
    position: absolute;
    bottom: 0px;
    right: 10px;
    border: 1px dashed black;
    padding: 2px 5px;
    opacity: 0.6;
    transition: opacity 0.2s 0s ease;
  }
  &:hover:after {
    border: 1px solid black;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 0.2s 0.2s ease;
  }
  &.opened:after {
    content: '↑ Less...';
    position: absolute;
    bottom: 0px;
    right: 10px;
    border: 1px dashed black;
    padding: 2px 5px;
  }
  &.opened:hover:after {
    opacity: 1;
  }
`

// TODO: REFACTOR IT!!!
export const Skills = ({ skills }) => {
  const minHeight = 300
  let [height, setHeight] = useState(minHeight)
  return (
    <SectionShowMore
      name='skills'
      id='skills'
      className={`col gap-4 ${height === 0 ? 'opened' : ''}`}
      style={{ maxHeight: `${height === 0 ? '100%' : `${height}px`}` }}
      onClick={() => setHeight(height === 0 ? minHeight : 0)}
    >
      <h1>Skills</h1>
      {map(Object.keys(skills), (listName, index) => (
        <React.Fragment key={index}>
          <b>{listName}</b>
          <div>
            {map(skills[listName], (s, i) => (
              <span key={i}>{i === 0 ? s : ` | ${s}`}</span>
            ))}
          </div>
        </React.Fragment>
      ))}
    </SectionShowMore>
  )
}
