export default [
  {
    title: 'Fullstack Javascript Developer',
    company: 'Pozitiff 🇨🇿',
    companyWebsite: 'https://pozitiff.cz',
    description: 'No details, NDA.',
    dates: '06.2017 - present',
  },
  {
    title: 'Frontend Javascript Developer',
    company: 'Toreador 🇨🇦',
    companyWebsite: 'https://toreador.io',
    description:
      'Cryptocurrency autotrader. I have worked with React/Redux on this project.',
    dates: '03.2018 - 06.2018',
  },
  {
    title: 'Backend Javascript Developer',
    company: 'Controlio 🇨🇦',
    companyWebsite: 'https://itunes.apple.com/ru/app/controlio/id997857994',
    description:
      'Successfully closed tasks assigned to me by scrum manager, covered my code with unit tests.',
    dates: '02.2017 - 05.2017',
  },
  {
    title: 'Web Developer',
    company: 'China Trip of Garden International Tour Co.,Ltd 🇨🇳',
    companyWebsite: 'https://tripofgarden.com',
    description: `Responsibilities: %br%
    - Developing content parsers for a lot of sources; %br%
    - Developing SPA/MPA websites; %br%
    - Administrating servers and DB; %br%
    - Supporting legacy projects (ASP.NET/C#/MSSQL);`,
    dates: '09.2015 - 11.2017',
  },
  {
    title: 'IT Specialist',
    company: 'Odessa National Medical University 🇺🇦',
    companyWebsite: 'http://onmedu.edu.ua/',
    description: `Accomplishments: %br%
    - A large contribution to the transition to electronic document management. %br%
    - The implementation of student friendly visits library foreign students. %br%
    - Participation in the development of an information system for electronic library. %br%
    - Regular training sessions with employees to improve their skills in using the computer at work. My initiative. %br% %br%
    Responsibilities: %br%
    - Service the database server. %br%
    - Service the Working computers and terminals. %br%
    - Service the scanner for books and the rest of the periphery. %br%
    - Creation and support an institutional repository (DSpace).`,
    dates: '06.2011 - 08.2015',
  },
  {
    title: 'Network System Administrator',
    company: 'Vita-service 🇺🇦',
    companyWebsite: '',
    description: `Responsibilities: %br%
    - Served client computers in offices and at home. %br%
    - Cabling, creation and setup networks.`,
    dates: '02.2010 - 06.2011',
  },
]
