import React from 'react'
import configs from '../../configs'
import {
  MainLayout,
  SEO,
  About,
  Top,
  // News,
  Publications,
  Employment,
  Education,
  Contact,
  Skills,
  LiveChatBot,
} from '../components'

const { employments, schools, contacts, skills, publications } = configs

const IndexPage = () => (
  <MainLayout>
    <SEO
      title='johnnykramer'
      keywords={[
        'johnnykramer',
        'developer',
        'javascript',
        'nodejs',
        'react',
        'fullstack',
        'petro kuzmychev',
      ]}
    />
    <Top title='Petro Kuzmychev' />
    <hr />
    <About />
    {/* <hr /> */}
    {/* <News /> */}
    <hr />
    <Publications publications={publications} />
    <hr />
    <Skills skills={skills} />
    <hr />
    <Employment employments={employments} />
    <hr />
    <Education schools={schools} />
    <hr />
    <Contact contacts={contacts} />
    <LiveChatBot />
  </MainLayout>
)

export default IndexPage
