import React from 'react'
import { EmploymentItem } from '..'

export const Employment = ({ employments }) => {
  return (
    <section name='employment' id='employment' className='col gap-4'>
      <h1>Employment</h1>
      {employments.map((e, i) => (
        <EmploymentItem work={e} key={i} />
      ))}
    </section>
  )
}
