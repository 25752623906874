import React from 'react'
import { Link } from '..'

export const PublicationsItem = ({ publication }) => {
  const { title, description, linkName, link } = publication
  return (
    <div className='publication'>
      <h3 className='publication-title'>{title}</h3>
      <p>{description}</p>[
      <Link to={link} target='_blank'>
        {linkName}
      </Link>
      ]
    </div>
  )
}
