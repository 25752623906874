import React from 'react'
import { Link } from '..'

export const EmploymentItem = ({ work }) => {
  let { title, company, companyWebsite, description, dates } = work
  description = description.replace(/%br%/g, '<br />')
  return (
    <div className='col'>
      <div className='col'>
        <span className='faded date xl-pop-right'>{dates}</span>
        <span>
          <b>{title}</b>,{' '}
          {companyWebsite ? (
            <Link target='_blank' to={companyWebsite}>
              {company}
            </Link>
          ) : (
            company
          )}
        </span>
      </div>
      {description && (
        <p
          className='small'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  )
}
