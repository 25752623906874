import employments from './employments'
import schools from './schools'
import contacts from './contacts'
import skills from './skills'
import publications from './publications'

export default {
  employments,
  schools,
  contacts,
  skills,
  publications,
}
