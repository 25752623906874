export default [
  {
    service: 'Telegram',
    iconName: 'FaTelegram',
    title: '@johnnykramer',
    link: 'https://t.me/johnnykramer',
  },
  {
    service: 'Github',
    iconName: 'FaGithub',
    title: 'http://github.com/johnnykramer',
    link: 'http://github.com/johnnykramer',
  },
  {
    service: 'LinkedIn',
    iconName: 'FaLinkedin',
    title: 'https://linkedin.com/in/johnnykramer',
    link: 'https://linkedin.com/in/johnnykramer',
  },
  {
    service: 'Facebook',
    iconName: 'FaFacebook',
    title: 'https://facebook.com/kuzmychev',
    link: 'https://facebook.com/kuzmychev',
  },
  {
    service: 'E-mail',
    iconName: 'FaEnvelope',
    title: 'kuzmychev@gmail.com',
    link: 'mailto:kuzmychev@gmail.com',
  },
]
