import React from 'react'
import { EducationItem } from '..'

export const Education = ({ schools }) => {
  return (
    <section name='education' id='education' className='col gap-4'>
      <h1>Education</h1>
      {schools.map((s, i) => (
        <EducationItem school={s} key={i} />
      ))}
    </section>
  )
}
