import React from 'react'
import { Image, Link } from '../../components'

export const About = () => {
  return (
    <section name='about' id='about' className='gap-2 row xl-gap-4'>
      <div className='article'>
        <Image filename='me.jpg' className='me' alt='Petro Kuzmychev' />
        <p>
          I am <b>Fullstack Web Developer</b>
          <br />I have more than 3 years of experience in developing e-commerce
          systems with secure payment processing, complex business logic, based
          on microservice or monolithic architecture. I love to meet interesting
          challenges and solve the problems. I enjoy work with new technologies
          and travel :)
          <br />
          Now I live in the sunny{' '}
          <Link target='_blank' to='https://nomadlist.com/tbilisi'>
            Tbilisi
          </Link>
          , breath a clean mountain air, play frisbee and eat khinkali.
        </p>
      </div>
    </section>
  )
}
