import React from 'react'
import './MainLayout.css'

export const MainLayout = ({ children }) => {
  return (
    <>
      <div className='main page col justify gap-4' role='main'>
        {children}
      </div>
    </>
  )
}
