import React from 'react'
import * as fa from 'react-icons/fa'
import { Link } from '..'

export const ContactItem = ({ contact }) => {
  const { service, title, link, iconName } = contact
  const Icon = fa[iconName]
  return (
    <span className='col xl-row'>
      <b className='fill-1'>
        <Icon /> {service}
      </b>
      <Link className='fill-6' target='_blank' to={link}>
        {title}
      </Link>
    </span>
  )
}
