export default {
  frontend: [
    'Javascript',
    'ReactJS',
    'Meteor',
    'Gatsby',
    'JAM-stack',
    'jQuery',
    'HTML',
    'CSS/SASS',
    'Blaze',
    'Handlebars',
    'GraphQL',
  ],
  backend: [
    'NodeJS',
    'Express',
    'Meteor',
    'Serverless',
    'MongoDB',
    'Redis',
    'AWS Lambda',
    'AWS API Gateway',
    'AWS SQS',
    'AWS SNS',
  ],
  tools: ['Git', 'Nanobox', 'Cloudflare', 'c9', 'pm2'],
  services: ['Segment', 'Manychat', 'ClickFunnels', 'FunnelFlux'],
  'payment systems': ['Braintree', 'Clickbank', 'Stripe', 'Limelight'],
  lang: ['English (intermediate)', 'Russian (native)', 'Ukrainian (native)'],
}
