export default [
  {
    title: 'Novel coronavirus (2019-nCoV) Interactive Map',
    description:
      'Interactive map of the spread of the 2019-nCoV coronavirus around the world.',
    linkName: 'link',
    link: 'https://map.johnnykramer.dev/',
  },
  {
    title: 'chanced-element',
    description:
      'NPM package for getting a random array element with a specific chance',
    linkName: 'link',
    link: 'https://github.com/johnnykramer/chanced-element',
  },
  {
    title: 'Redirect Tracer',
    description: 'A tool for tracking the redirections path of URLs.',
    linkName: 'link',
    link: 'https://redirects.show',
  },
  {
    title: 'Crypto Reporter',
    description:
      'Telegram bot notifies when crypto exchanges adds new coins/tokens. Contact me if you need details or access.',
    linkName: 'contact me',
    link: 'https://t.me/johnnykramer',
  },
  {
    title: 'tglogger',
    description:
      'NPM package which able to log any text directly to you or your channel on Telegram.',
    linkName: 'link',
    link: 'https://github.com/johnnykramer/tglogger',
  },
  {
    title: 'liveedu-status',
    description: 'NPM package which can get status of LiveEdu channel.',
    linkName: 'link',
    link: 'https://github.com/johnnykramer/liveedu-status',
  },
]
