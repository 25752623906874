import React from 'react'
import { Link } from '..'

export const EducationItem = ({ school }) => {
  const { title, schoolName, schoolWebsite, dates } = school
  return (
    <div className='col'>
      <div className='col'>
        <span className='faded date xl-pop-right'>{dates}</span>
        <span>
          <b>{title}</b>,{' '}
          {schoolWebsite ? (
            <Link target='_blank' to={schoolWebsite}>
              {schoolName}
            </Link>
          ) : (
            schoolName
          )}
        </span>
      </div>
    </div>
  )
}
