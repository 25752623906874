import React from 'react'
import { PublicationsItem } from '..'

export const Publications = ({ publications }) => {
  return (
    <section name='publications' id='publications' className='col gap-4'>
      <h1>Pet projects</h1>
      {publications.map((p, i) => (
        <PublicationsItem publication={p} key={i} />
      ))}
    </section>
  )
}
